import { useQuery } from '@tanstack/react-query'
import apiClient from '../../lib'

export function fetchMicroDashboardData() {
  const userId = JSON.parse(localStorage.getItem('user'))?.oData?._id;
  return apiClient.get(`dashboard/micro-dashboard${userId ? `?userId=${userId}` : ''}`);
}


export const useFetchMicroDashboardData = () => {
  return useQuery({
    queryKey: ['microDashboard'],
    queryFn: fetchMicroDashboardData,
    select: (data) => data?.data?.oData
  })
}
