import React, { useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { FaHome, FaAngleRight, FaRegFolder } from 'react-icons/fa'
import PropTypes from 'prop-types'
import useUser from '../../shared/hooks/useGetProfile'
import userImg from '../../assets/Images/userImg.png'

import { IoDocumentAttachOutline } from 'react-icons/io5'
import { MdOutlineSwitchAccount } from 'react-icons/md'
import { FiShoppingBag } from 'react-icons/fi'
import { GiNewspaper } from 'react-icons/gi'
import { BsCalendar } from 'react-icons/bs'

const Sidebar = ({ toggleSidebar }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { user, isLoading } = useUser()
  const [sections, setSections] = useState({
    default: false,
    details: false,
    regSegments: false,
    relevantNews: false,
    saveDocuments: false,
    calendar: false,
    account: false,
    chat: false,
    corporate: false,
    blog: false,
    social: false
  })

  const isActivePath = (path) => {
    return location.pathname.startsWith(path)
  }

  const toggleSection = (sectionId, path) => {
    setSections((prevState) => ({
      ...prevState,
      [sectionId]: !prevState[sectionId]
    }))
    navigate(path)
  }

  return (
    // <div
    //   className={`fixed inset-y-0 left-0 transform ${
    //     isSidebarOpen ? "translate-x-0" : "-translate-x-full"
    //   } transition-transform duration-300 ease-in-out bg-primary-600 w-64`}
    // >
    <aside className='bg-primary-600 text-primary-500 h-full space-y-6 py-7 px-2 border-r-2 border-primary-300 w-[210px]'>
      <div className='flex items-center space-x-2 px-2'>
        <img src={userImg} alt='Profile' className='rounded-full' onClick={toggleSidebar} />
        <span className='text-sm text-primary-500 font-thin leading-5'>
          {isLoading ? 'Loading...' : user?.data?.oData?.sBusinessName || 'Sweephy'}
        </span>
      </div>

      <nav style={{cursor:"pointer"}}>
        <div className='flex justify-center items-center gap-11 text-secondary-200'>
          <div>
            <h4>Favorite</h4>
          </div>
          <div>
            <h4>Recently</h4>
          </div>
        </div>
        <NavLink
          to='/dashboard/overview'
          className='block py-1 px-6 rounded transition duration-200 hover:bg-secondary-200  before:before-dot before:text-secondary-200'
        >
          Overview
        </NavLink>
        <NavLink
          to='/dashboard/jurisdiction'
          className='block py-1 px-6 rounded transition duration-200 hover:bg-secondary-200 before:before-dot before:text-secondary-200'
        >
          Jurisdictions
        </NavLink>
        <div className='text-sm font-normal '>
          <h4 className='block py-1 px-6 rounded transition duration-200 text-secondary-200'>Dashboards</h4>

          <div
            className={`py-2.5 px-4 rounded transition duration-200 hover:bg-secondary-200 flex items-center justify-between ${
              isActivePath('/dashboard/default') ? 'border-l-4 border-black' : ''
            }`}
            onClick={() => toggleSection('default', '/dashboard/default')}
          >
            <div className='flex items-center'>
              <FaAngleRight className={`transition-transform ${sections.default ? 'rotate-90' : ''} fill-gray`} />
              <FaHome className='ml-1' />
              <span className='ml-1'>Default</span>
            </div>
          </div>
          {sections.default && (
            <>
            </>
          )}

          <div
            className={`py-2.5 px-4 rounded transition duration-200 hover:bg-secondary-200 flex items-center justify-between ${
              isActivePath('/dashboard/default/chatbox') ? 'border-l-4 border-black' : ''
            }`}
            onClick={() => toggleSection('chat', '/dashboard/default/chatbox')}
          >
            <div className='flex items-center'>
              <FaAngleRight className={`transition-transform ${sections.chat ? 'rotate-90' : ''}`} fill='gray' />
              <span className='ml-1'>Chat</span>
            </div>
          </div>

          <div
            className={`py-2.5 px-4 rounded transition duration-200 hover:bg-secondary-200 flex items-center justify-between ${
              isActivePath('/dashboard/details') ? 'border-l-4 border-black' : ''
            }`}
            onClick={() => toggleSection('details', '/dashboard/details')}
          >
            <div className='flex items-center'>
              <FaAngleRight className={`transition-transform ${sections.details ? 'rotate-90' : ''}`} fill='gray' />
              <FiShoppingBag className='ml-1' />
              <span className='ml-1'>Details</span>
            </div>
          </div>
          {sections.details && (
            <NavLink to='/dashboard/details' className='block py-2.5 px-6 rounded transition duration-200 hover:bg-secondary-200'>
              Details
            </NavLink>
          )}

          <div
            className={`py-2.5 px-4 rounded transition duration-200 hover:bg-secondary-200 flex items-center justify-between ${
              isActivePath('/dashboard/regsegments') ? 'border-l-4 border-black' : ''
            }`}
            onClick={() => toggleSection('regSegments', '/dashboard/regsegments')}
          >
            <div className='flex items-center'>
              <FaAngleRight className={`transition-transform ${sections.regSegments ? 'rotate-90' : ''}`} fill='gray' />
              <FaRegFolder className='ml-1' />
              <span className='ml-1'>Documents</span>
            </div>
          </div>
          {sections.profile && (
            <NavLink to='/dashboard/regsegments' className='block py-2.5 px-6 rounded transition duration-200 hover:bg-secondary-200'>
              Reg. Segments
            </NavLink>
          )}

          <div
            className={`py-2.5 px-4 rounded transition duration-200 hover:bg-secondary-200 flex items-center justify-between ${
              isActivePath('/dashboard/relevantNews') ? 'border-l-4 border-black' : ''
            }`}
            onClick={() => toggleSection('relevantNews', '/dashboard/relevantNews')}
          >
            <div className='flex items-center'>
              <FaAngleRight className={`transition-transform ${sections.relevantNews ? 'rotate-90' : ''}`} fill='gray' />
              <GiNewspaper className='ml-1' />
              <span className='ml-1'>Relevant News</span>
            </div>
          </div>
        </div>

        {/* pages */}
        <div className='pt-7 text-sm font-normal'>
          <h4 className='block py-1 px-6 rounded transition duration-200 text-secondary-200'>Pages</h4>
          <div
            className={`py-2.5 px-4 rounded transition duration-200 hover:bg-secondary-200 flex items-center justify-between ${
              isActivePath('/dashboard/saveDocuments') ? 'border-l-4 border-black' : ''
            }`}
            onClick={() => toggleSection('saveDocuments', '/dashboard/saveDocuments')}
          >
            <div className='flex items-center'>
              <FaAngleRight className={`transition-transform ${sections.saveDocuments ? 'rotate-90' : ''}`} fill='gray' />
              <IoDocumentAttachOutline className='ml-1' />
              <span className='ml-1'>Saved documents</span>
            </div>
          </div>

          {/* {sections.default && (
            <NavLink
              to='/dashboard/saveDocuments'
              className='block py-2.5 px-6 rounded transition duration-200 hover:bg-secondary-200'
            >
              Documents
            </NavLink>
          )} */}

          <div
            className={`py-2.5 px-4 rounded transition duration-200 hover:bg-secondary-200 flex items-center justify-between ${
              isActivePath('/dashboard/calendar') ? 'border-l-4 border-black' : ''
            }`}
            onClick={() => toggleSection('calendar', '/dashboard/calendar')}
          >
            <div className='flex items-center'>
              <FaAngleRight className={`transition-transform ${sections.calendar ? 'rotate-90' : ''}`} fill='gray' />
              <BsCalendar className='ml-1' />
              <span className='ml-1'>Calendar</span>
            </div>
          </div>

          <div
            className={`py-2.5 px-4 rounded transition duration-200 hover:bg-secondary-200 flex items-center justify-between ${
              isActivePath('/dashboard/account') ? 'border-l-4 border-black' : ''
            }`}
            onClick={() => toggleSection('account', '/dashboard/account')}
          >
            <div className='flex items-center'>
              <FaAngleRight className={`transition-transform ${sections.account ? 'rotate-90' : ''}`} fill='gray' />
              <MdOutlineSwitchAccount className='ml-1' />
              <span className='ml-1'>Account</span>
            </div>
          </div>
        </div>
      </nav>
    </aside>
    // </div>
  )
}
export default Sidebar

Sidebar.propTypes = {
  toggleSidebar: PropTypes.func.isRequired
}
