import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import RedFlagTable from '../../shared/ui/RedFlagTable'
import LatestNewsTable from '../../shared/ui/LatestNewsTable'
import DetailsTable from '../../shared/ui/DetailsTable'
import { CategoryScale } from 'chart.js'
import Chart from 'chart.js/auto'
import BarChartComponent from '../../shared/ui/BarChart'
import { useFetchMicroDashboardData } from '../../Query/Dashboard/microDashboardQuery'
import NotificationsTable from '../../shared/ui/NotificationsTable'

Chart.register(CategoryScale)

const Default = () => {
  const location = useLocation()
  const isNestedRoute = location.pathname.includes('/dashboard/default/')

  const { data, isLoading, error } = useFetchMicroDashboardData()



  if (isLoading) return <div>Loading...</div>
  // TODO: Burda normalde or data == null checki yok, ben ekledim
  if (error) return <div>Error loading data</div>
  console.log('data', data)
  console.log('data.sLastFines', data)
  return (
    <>
      {!isNestedRoute ? (
        <>
          <section className='pb-7'>
            <h2 className='text-sm font-semibold pb-5'>Default</h2>
            <div className='flex flex-row gap-7'>
              {' '}
              <div className='basis-1/2 '>
                <div className='flex justify-between gap-4 pb-7'>
                  <div className='p-6 bg-secondary-300 rounded-2xl w-full flex flex-col justify-start'>
                    <h2 className='text-sm font-semibold pb-2'>Scanned documents</h2>
                    <div className='flex items-center gap-3'>
                      <h4 className='text-2xl font-semibold leading-8'>{data?.nScannedDocument}</h4>
                      <p className='text-base font-normal'>Regulation scanned</p>
                    </div>
                  </div>

                  <div className='p-6 bg-primary-600 rounded-2xl w-full'>
                    <h2 className='text-sm font-semibold pb-2'>Latest Fines</h2>
                    <div className='flex items-center gap-3'>
                      <h4 className='text-2xl font-semibold leading-8'>
                        {/* {" €134K"} */}
                        €{data?.sLastFines}
                      </h4>
                      <p className='text-base font-normal'> %{data?.sLastFinesPercentage}</p>
                    </div>
                  </div>
                </div>

                <div className='p-6 bg-primary-600 rounded-2xl w-full'>
                  <h2 className='text-sm font-semibold pb-2'>Red Flags - Needs to be checked</h2>
                  <div className='flex justify-between items-center gap-3'>
                    <h4 className='text-2xl font-semibold leading-8'>{data?.nScannedDocument}</h4>
                    <p className='text-base font-normal'>{data?.nScannedDocument}</p>
                  </div>
                </div>
              </div>
              <div className='basis-1/2 pl-7'>
                <div className='chart-container p-5 bg-primary-600 rounded-2xl w-full'>
                  <h2 style={{ textAlign: 'start' }} className='font-semibold'>
                    Monthly fixed/non-fixed warnings
                  </h2>
                  <BarChartComponent />
                </div>
              </div>
            </div>
          </section>
          <RedFlagTable />
          <LatestNewsTable />
          <NotificationsTable />
        </>
      ) : (
        <Outlet />
      )}
    </>
  )
}

export default Default
