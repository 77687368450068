import React, { useState, useEffect } from 'react';
import { Calendar, Clock, AlertTriangle, TrendingUp, FileText, Bell } from 'react-feather';

// Backend API endpoints (TO BE IMPLEMENTED)
// GET /api/compliance/stats - Key compliance metrics and regulatory status
// GET /api/compliance/news - Latest regulatory news and compliance updates
// GET /api/compliance/calendar - Important compliance deadlines and audits
// GET /api/compliance/ai-summary - AI-generated compliance analysis and risk assessment

function DashboardOverview() {
  // Add state management
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState({
    companyStats: {
      complianceRate: "94%",
      activeRegulations: "156",
      pendingActions: "14",
      riskLevel: "Low",
    },
    latestNews: [],
    upcomingDeadlines: [],
    aiSummary: ''
  });

  // Simulate API fetch
  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        setLoading(true);
        const mockData = {
          companyStats: {
            complianceRate: "94%",
            activeRegulations: "156",
            pendingActions: "14",
            riskLevel: "Low",
          },
          latestNews: [
            {
              id: 1,
              title: "New Data Protection Regulations Announced",
              date: "2024-03-12",
              priority: "High",
              summary: "EU announces enhanced data protection requirements affecting cross-border data transfers. Compliance deadline set for Q3 2024."
            },
            {
              id: 2,
              title: "Industry Safety Standards Update",
              date: "2024-03-10",
              priority: "Medium",
              summary: "Updated workplace safety protocols released by regulatory board. Companies must implement changes within 6 months."
            },
            {
              id: 3,
              title: "Environmental Compliance Report",
              date: "2024-03-08",
              priority: "Medium",
              summary: "New carbon emission reporting requirements for manufacturing sector. Annual submissions required starting 2025."
            }
          ],
          upcomingDeadlines: [
            {
              id: 1,
              title: "Annual Compliance Audit",
              dueDate: "2024-03-31",
              status: "Pending",
              description: "External auditor review of compliance processes and documentation"
            },
            {
              id: 2,
              title: "Safety Certification Renewal",
              dueDate: "2024-03-25",
              status: "In Progress",
              description: "Workplace safety certification renewal for main facility"
            },
            {
              id: 3,
              title: "Regulatory Training Deadline",
              dueDate: "2024-03-20",
              status: "Pending",
              description: "Staff completion of updated regulatory compliance training"
            }
          ],
          aiSummary: "Company maintains strong compliance status with 94% adherence rate to current regulations. Recent focus areas include data protection updates and workplace safety improvements. Key attention needed for upcoming annual audit and certification renewals. Overall risk assessment remains low with proper controls in place. Recommended actions include completing staff training and updating documentation for new environmental requirements."
        };
        
        // Simulate network delay
        await new Promise(resolve => setTimeout(resolve, 1000));
        setData(mockData);
      } catch (err) {
        setError('Failed to load dashboard data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  // Add click handlers
  const handleNewsClick = (newsItem) => {
    console.log('News clicked:', newsItem);
    // Add navigation or modal logic here
  };

  const handleDeadlineClick = (deadline) => {
    console.log('Deadline clicked:', deadline);
    // Add navigation or modal logic here
  };

  // Loading state
  if (loading) {
    return (
      <div className="p-6 flex items-center justify-center min-h-screen">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto"></div>
          <p className="mt-4 text-gray-600">Loading dashboard...</p>
        </div>
      </div>
    );
  }

  // Error state
  if (error) {
    return (
      <div className="p-6">
        <div className="bg-red-50 border-l-4 border-red-400 p-4 rounded">
          <div className="flex items-center">
            <AlertTriangle className="text-red-400 mr-2" size={20} />
            <p className="text-red-700">{error}</p>
          </div>
          <button 
            onClick={() => window.location.reload()} 
            className="mt-2 text-red-600 hover:text-red-800 underline"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="p-6">
      {/* Stats Overview */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
        <div className="bg-white rounded-lg shadow p-4">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-gray-500 text-sm">Compliance Rate</p>
              <p className="text-2xl font-bold">{data.companyStats.complianceRate}</p>
            </div>
            <TrendingUp className="text-blue-500" size={24} />
          </div>
        </div>
        <div className="bg-white rounded-lg shadow p-4">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-gray-500 text-sm">Active Regulations</p>
              <p className="text-2xl font-bold">{data.companyStats.activeRegulations}</p>
            </div>
            <FileText className="text-green-500" size={24} />
          </div>
        </div>
        <div className="bg-white rounded-lg shadow p-4">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-gray-500 text-sm">Pending Actions</p>
              <p className="text-2xl font-bold">{data.companyStats.pendingActions}</p>
            </div>
            <Bell className="text-yellow-500" size={24} />
          </div>
        </div>
        <div className="bg-white rounded-lg shadow p-4">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-gray-500 text-sm">Risk Level</p>
              <p className="text-2xl font-bold">{data.companyStats.riskLevel}</p>
            </div>
            <AlertTriangle className="text-purple-500" size={24} />
          </div>
        </div>
      </div>

      {/* AI Summary Section */}
      <div className="mb-6">
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-xl font-bold mb-3">Overview Summary</h2>
          <p className="text-gray-600 leading-relaxed">
            {data.aiSummary}
          </p>
        </div>
      </div>

      {/* Main Content Grid */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* News Section */}
        <div className="lg:col-span-2">
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-xl font-bold mb-4">Latest Updates</h2>
            <div className="space-y-4">
              {data.latestNews.map((news) => (
                <div 
                  key={news.id} 
                  className="border-b pb-4 cursor-pointer hover:bg-gray-50 transition-colors rounded p-2"
                  onClick={() => handleNewsClick(news)}
                  role="button"
                  tabIndex={0}
                >
                  <div className="flex justify-between items-start">
                    <div>
                      <h3 className="font-semibold">{news.title}</h3>
                      <p className="text-sm text-gray-600 mt-1">{news.summary}</p>
                      <p className="text-sm text-gray-500 mt-2">{news.date}</p>
                    </div>
                    <span className={`px-2 py-1 rounded text-xs ${
                      news.priority === 'High' ? 'bg-red-100 text-red-800' : 
                      'bg-yellow-100 text-yellow-800'
                    }`}>
                      {news.priority}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Upcoming Deadlines */}
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-xl font-bold mb-4">Upcoming Deadlines</h2>
          <div className="space-y-4">
            {data.upcomingDeadlines.map((deadline) => (
              <div 
                key={deadline.id} 
                className="flex items-center space-x-4 border-b pb-4 cursor-pointer hover:bg-gray-50 transition-colors rounded p-2"
                onClick={() => handleDeadlineClick(deadline)}
                role="button"
                tabIndex={0}
              >
                <Calendar className="text-gray-400" size={20} />
                <div className="flex-1">
                  <h3 className="font-semibold">{deadline.title}</h3>
                  <p className="text-sm text-gray-600 mt-1">{deadline.description}</p>
                  <p className="text-sm text-gray-500">Due: {deadline.dueDate}</p>
                  <span className={`inline-block px-2 py-1 rounded text-xs mt-1 ${
                    deadline.status === 'Pending' ? 'bg-yellow-100 text-yellow-800' : 
                    'bg-blue-100 text-blue-800'
                  }`}>
                    {deadline.status}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardOverview;
