import React from 'react'
import PropTypes from 'prop-types'
import { useOutletContext } from "react-router-dom"
import { timeAgo } from '../../shared/constants/constant'

function NotificationsTable() {
  const context = useOutletContext()
  const notifications = context?.aNotifications || []

  return (
    <section className="pb-7">
      <div className="flex flex-row w-full">
        <div className="basis-full w-full">
          <div className="p-6 bg-primary-600 rounded-2xl w-full h-full">
            <h2 className="text-sm font-semibold pb-2">National Case Laws</h2>
            <div className="flex flex-col">
              <div className="text-justify text-xs text-secondary-200 font-normal border-b-2 border-secondary-200 py-2 flex">
                <div className="w-3/6">Title</div>
                <div className="w-1/4 flex items-center">Date</div>
                <div className="w-1/4 flex items-center">Link</div>
              </div>
              <div className="text-xs font-normal flex flex-col py-2">
                {notifications?.map((notification) => (
                  <div key={notification._id} className="py-2 flex">
                    <div className="w-3/6">{notification.sTitle}</div>
                    <div className="w-1/4">{timeAgo(new Date(notification.dPubDate))}</div>
                    <div className="w-1/4">
                      <a 
                        href={notification.sEurLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-400 hover:text-blue-600 hover:underline"
                      >
                        View Document
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default NotificationsTable

NotificationsTable.propTypes = {
  data: PropTypes.any
} 