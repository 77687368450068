import React, { Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'

import Dashboard from '../layouts/dashboardLayout/DashboardLayout'
import Default from '../views/default/Default'
import { useAuth } from '../shared/hooks/useAuthenticationState'
import DashboardOverview from '../views/dashboardOverview/DashboardOverview'
import DashboardJurisdiction from '../views/dashboardJurisdictions/DashboardJurisdiction'
import allRoutes from '../lib/allRoutes'
import ForgotPassword from '../shared/components/ForgotPassword'
import SetForgotPassword from '../shared/components/SetForgotPassword'
import { ProtectedRoute } from './protected'
import EmailVerifyPage from '../views/email-verify/EmailVerify'
import PageNotFound from '../views/pageNotFound/PageNotFound'
import ThankYou from '../shared/ui/Thankyou'
import Calendar from '../shared/ui/Calendar'

const Login = React.lazy(() => import('../views/login/Login'))
const OnBoarding = React.lazy(() => import('../views/onboarding/OnBoarding'))
const ChatBox = React.lazy(() => import('../shared/ui/ChatBox'))
const Details = React.lazy(() => import('../shared/ui/Settings'))
const RegSegment = React.lazy(() => import('../shared/ui/RegSegment'))
const RelevantNews = React.lazy(() => import('../shared/ui/RelevantNews'))
const SaveDocuments = React.lazy(() => import('../shared/ui/SaveDocuments'))
const Corporate = React.lazy(() => import('../shared/ui/Corporate'))
const Social = React.lazy(() => import('../shared/ui/Social'))
const Account = React.lazy(() => import('../shared/ui/Account'))
const Blog = React.lazy(() => import('../shared/ui/Blog'))
const LoadingYourDashboard = React.lazy(() => import('../views/loadingYourDashboard/LoadingYourDashboard'))

const AllRoutes = () => {
  const { isAuthenticated } = useAuth()

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path='/' element={<Navigate to={isAuthenticated ? allRoutes.dashboard : allRoutes.login} />} />
        <Route path='/login' element={isAuthenticated ? <Navigate to={allRoutes.dashboard} /> : <Login />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/thank-you' element={<ThankYou />} />
        <Route path='/set-forgot-password/:iForgotRequestId' element={<SetForgotPassword />} />
        <Route path='/signup' element={isAuthenticated ? <Navigate to={allRoutes.dashboard} /> : <Login />} />
        <Route path='/onboarding' element={isAuthenticated ? <Navigate to={allRoutes.dashboard} /> : <OnBoarding />} />

        <Route
          path='/dashboard/*'
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        >
          <Route index element={<Default />} />
          <Route path='default' element={<Default />}>
            <Route path=':chatbox' element={<ChatBox />} />
          </Route>
          <Route path='overview' element={<DashboardOverview />} />
          <Route path='jurisdiction' element={<DashboardJurisdiction />} />
          <Route path='details' element={<Details />} />
          <Route path='regSegments' element={<RegSegment />} />
          <Route path='relevantNews' element={<RelevantNews />} />
          <Route path='savedocuments' element={<SaveDocuments />} />
          <Route path='account' element={<Account />} />
          <Route path='corporate' element={<Corporate />} />
          <Route path='blog' element={<Blog />} />
          <Route path='social' element={<Social />} />
          <Route path='calendar' element={<Calendar />} />
        </Route>
        <Route path='/loadingYourDashboard' element={<LoadingYourDashboard />} />
        <Route path='/email-verify/:iEmailVerifyId' element={<EmailVerifyPage />} />
        <Route path='*' element={<PageNotFound />} />
      </Routes>
    </Suspense>
  )
}

export default AllRoutes
