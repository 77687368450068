import axios from 'axios'
import { USER_BASE_URL } from '../shared/constants/constant'
import { isUserLoggedIn } from '../shared/utils'
import { toast } from 'react-toastify'

const apiClient = axios.create({
  baseURL: USER_BASE_URL,
  // timeout: 30000,
})

// Request interceptor to add JWT to headers
apiClient.interceptors.request.use((config) => {
  const token = isUserLoggedIn()
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

// Response interceptor to handle token expiration
apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      // unauthorized
      localStorage.removeItem('jwtToken')
      toast('your session has expired, please login again')
      window.location.href = '/login'
    }
    return Promise.reject(error)
  }
)

export default apiClient
