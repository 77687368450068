import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";
import PropTypes from 'prop-types'
import DetailsTable from './DetailsTable';

function RedFlagTable() {
  const context = useOutletContext();
  const relevantNotes = context?.oGeneralOverView?.aRelevantNote || [];
  const [selectedDocument, setSelectedDocument] = useState(null);

  const handleFlagClick = (flag) => {
    const matchingDocument = context?.oReport?.find(report => 
      report.title === flag.sNote
    );
    setSelectedDocument(matchingDocument);
  };

  return (
    <section className="pb-7">
      <div className="flex flex-row w-full">
        <div className="basis-full w-full">
          <div className="p-6 bg-primary-600 rounded-2xl w-full h-full">
            <h2 className="text-sm font-semibold pb-2">
              Relevant Regulations and Reports 
            </h2>
            <div className="flex flex-col">
              <div className="text-justify text-xs text-secondary-200 font-normal border-b-2 border-secondary-200 py-2 flex">
                <div className="w-3/4">
                  Reference (Click the here to see detailed report)
                </div>
                <div className="w-1/4 flex justify-center">Document ID</div>
              </div>
              <div className="text-xs font-normal flex flex-col py-2">
                {relevantNotes?.map((flag) => (
                  <div key={flag._id} className="py-2 flex cursor-pointer hover:bg-primary-700">
                    <div className="w-3/4" onClick={() => handleFlagClick(flag)}>
                      {flag.sNote}
                    </div>
                    <div className="w-1/4 flex justify-center">
                      <a 
                        href={flag.sDocumentId}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors text-center"
                        onClick={(e) => e.stopPropagation()}
                      >
                        Go to official regulation
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="basis-1/4 pl-7">
          <JurisdictionPresenceChart />
        </div> */}
      </div>

      {selectedDocument && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-8 max-w-3xl max-h-[90vh] overflow-auto">
            <button 
              className="float-right text-gray-600 hover:text-gray-800"
              onClick={() => setSelectedDocument(null)}
            >
              Close
            </button>
            <DetailsTable data={selectedDocument} />
          </div>
        </div>
      )}
    </section>
  );
}

export default RedFlagTable;
RedFlagTable.propTypes = {
  data: PropTypes.any
}
